<template>
  <el-table :data="tabelData" size="small" stripe>
    <el-table-column prop="eventType" label="操作类型"/>
    <el-table-column prop="eventContent" label="审核意见"/>
    <el-table-column prop="eventPerson" label="操作人"/>
    <el-table-column prop="createTime" label="操作时间">
      <template slot-scope="scope">
        <span>{{ parseTime(scope.row.createTime) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="转账凭证" width="150px" align="center">
      <template slot-scope="scope">
        <el-button
          type="primary"
          size="mini"
          class="my-button"
          v-if="scope.row.proofImg"
          @click="previewImg(scope.row.proofImg)"
        >查看图片
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
  import { parseTime } from '../../utils'

  export default {
    name: 'auditLogTable',
    props: {
      tabelData: {
        type: Array,
        default(){
          return [];
        }
      }
    },
    methods: {
      parseTime,
      previewImg(imgUrl) {
        this.$emit('preview', imgUrl);
      }
    }
  }
</script>

<style scoped>

</style>
