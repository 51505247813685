<template>
  <div>
    <el-button v-if="isCheck" size="mini" type="danger" @click="to">{{'审核'}}</el-button>
    <el-button
        v-if="checkBtnShow"
        size="mini"
        type="success"
        @click="to"
    >{{'查看'}}</el-button>
    <detail ref="detail" :sup_this="sup_this" :isCheck="isCheck" :data="data" :tableStatus="tableStatus"/>
  </div>
</template>
<script>
import checkPermission from '@/utils/permission'
import detail from './detail'
import { initData } from '@/api/data'

export default {
  components: { detail },
  props: {
     tableStatus: {
      type: Number,
      required: true
    },
    isCheck: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  computed: {
    // 有查看tab其中的一个tab权限就有查看的权限
    checkBtnShow() {
      return !this.isCheck && checkPermission(['ADMIN','WITHDRAW_INFO_ALL','WITHDRAW_APPLY_INFO','WITHDRAW_CUST_INFO','WITHDRAW_AUDIT_INFO']);
    }
  },
  methods: {
    to() {
      const _this = this.$refs.detail;
      var url = '/crm/tDepositWithdrawEvent';
      const params = { depositWithdrawId: this.data.id};
      _this.getPayFeeConfig();

      initData(url,params).then(res=>{
        _this.events=res.content;
        _this.dialog = true;
      }).catch(err => {
        console.log(err.response.data.message);
      })

    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
