<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            v-model="query.status"
            clearable
            placeholder="出金状态"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in withdrawStatusOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-select
            placeholder="出金类型"
            clearable
            v-model="query.withdrawType"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in withdrawTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
      />
    </el-select>
    <el-select
            multiple
            :collapse-tags="true"
            v-model="query.payType"
            clearable
            placeholder="支付渠道"
            class="filter-item"
            style="width: 220px"
            @change="toQuery"
    >
      <el-option
              v-for="item in outTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
      />
    </el-select>
    <el-input
      v-model="query.custInfo"
      clearable
      placeholder="客户姓名/ID"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-input
      v-model="query.transactionId"
      clearable
      placeholder="订单号"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-input
      v-model="query.login"
      clearable
      placeholder="MT账号"
      style="width: 150px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-input
      v-model="query.managerInfo"
      clearable
      placeholder="客户经理姓名/ID"
      style="width: 200px;"
      class="filter-item" @keyup.enter.native="toQuery"
    />

     <div class="filter-item">
    申请时间：
    <el-date-picker
      v-model="query.createTime"
      type="datetime"
      placeholder="开始日期">
    </el-date-picker>
    至
    <el-date-picker
      v-model="query.updateTime"
      type="datetime"
      placeholder="结束日期">
    </el-date-picker>
  </div>
    <div class="filter-item">
      审核通过时间：
      <el-date-picker
        v-model="query.startAuditTime"
        type="datetime"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        v-model="query.endAuditTime"
        type="datetime"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
    <el-button
            v-if="showExport"
            class="filter-item"
            size="mini"
            type="primary"
            @click="exportHandle"
            :loading="loading"
    >导出</el-button>
  </div>
</template>

<script>
  import { getPayTypeSelectList } from '@/api/cus/tCustDepositWithdraw'
import { mapGetters } from 'vuex'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
    showExport:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      outTypeOptions:[],
      withdrawTypeOption:[
        { value: 4, label: '出金至银行卡'},
        { value: 5, label: '出金至USDT'},
        { value: 6, label: '出金至OTC365'},
      ],
      loading: false,
    }
  },
  created(){
    this.getPayTypeList();
  },
  computed: {
    ...mapGetters([
      'withdrawStatusOptions'
    ])
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('query');
    },
    getPayTypeList(){
      getPayTypeSelectList({type: 2}).then((res) => {
        let tempArr = [];
        for(let key in res ){
          if(res.hasOwnProperty(key)){
            tempArr.push({
              value: key,
              label: res[key]
            })
          }
        }
        this.outTypeOptions = tempArr;
      })
    },
    clear(){
      this.$emit('clear')
    },
    exportHandle(){
      this.$emit('export');
    }
  }
}
</script>
